import { Api } from '@campus/auth';
import { Response } from '@campus/commons';
import LibraryObjectVideo from '../models/LibraryObjectVideo';
import LibraryObject from '../models/LibraryObject';
export const getLibraryObjects = async () => {
    try {
        const response = await Api.INSTANCE.getApi().get('/libraryobject/for-campus-knowledge');
        const result = {
            otherDigitalObjects: Array.isArray(response.data?.OtherDigitalObjects)
                ? response.data.OtherDigitalObjects.map((i) => LibraryObjectVideo.fromJson(i))
                : [],
            teachingMaterials: Array.isArray(response.data?.TeachingMaterials)
                ? response.data.TeachingMaterials.map((i) => LibraryObject.fromJson(i))
                : []
        };
        return Response.fromData(result, response.status);
    }
    catch (e) {
        return Response.error(e);
    }
};
