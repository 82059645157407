import React, { useEffect, useMemo, useState } from 'react';
import { includesCIAI } from '@campus/commons';
import { useDigitalObjectsPageContext } from '../../context';
import { BlackTitle, GreyTitle } from '../../styles';
import { Container, ObjectList } from './styles';
const TeachingMaterial = ({ items, loading }) => {
    const { events } = useDigitalObjectsPageContext();
    const [filteredItems, setFilteredItems] = useState(items);
    const mappedItems = useMemo(() => {
        return filteredItems.map((i) => i.mapToCommonLibraryObject());
    }, [filteredItems]);
    useEffect(() => {
        setFilteredItems(items);
    }, [items]);
    useEffect(() => {
        return events.genericFilterChanged.subscribe((genericFilter) => {
            if (!genericFilter) {
                setFilteredItems(items);
                return;
            }
            const newFilteredItems = items.filter((item) => (item.name && includesCIAI(item.name, genericFilter)) ||
                item.chapters?.some((c) => c.name && includesCIAI(c.name, genericFilter)));
            setFilteredItems(newFilteredItems);
        });
    }, [events.genericFilterChanged, items]);
    return (React.createElement(Container, null,
        React.createElement("div", null,
            React.createElement(GreyTitle, null, "Material "),
            React.createElement(BlackTitle, null, "Did\u00E1tico")),
        React.createElement(ObjectList, { items: mappedItems, loadingItems: loading })));
};
export default TeachingMaterial;
