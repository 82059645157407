import { Discipline, Grade } from '@campus/commons';
class LibraryObjectVideo {
    id;
    name;
    contentType;
    contentUrl;
    imageUrl;
    discipline;
    grade;
    duration;
    isAChapter;
    version;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static fromJson(data) {
        const video = new LibraryObjectVideo();
        video.id = data?.Id;
        video.name = data?.Name;
        video.contentType = data?.ContentType;
        video.contentUrl = data?.ContentUrl;
        video.imageUrl = data?.ImageUrl;
        if (data?.Discipline) {
            video.discipline = Discipline.fromJson(data?.Discipline);
        }
        if (data?.Grade) {
            video.grade = Grade.fromJson(data?.Grade);
        }
        video.duration = data?.Duration;
        video.isAChapter = data?.IsAChapter;
        video.version = data?.Version;
        return video;
    }
}
export default LibraryObjectVideo;
