import { LibraryObjectContentType, LibraryObjectChapter as CommonLibraryObjectChapter } from '@campus/commons';
class LibraryObjectChapter {
    id;
    name;
    contentType;
    contentUrl;
    imageUrl;
    static fromJson(data) {
        const libraryObjectChapter = new LibraryObjectChapter();
        libraryObjectChapter.id = data?.Id;
        libraryObjectChapter.name = data?.Name;
        libraryObjectChapter.contentType = data?.ContentType;
        libraryObjectChapter.contentUrl = data?.ContentUrl;
        libraryObjectChapter.imageUrl = data?.ImageUrl;
        return libraryObjectChapter;
    }
    mapToCommonLibraryObjectChapter() {
        if (!this)
            return null;
        const chapter = new CommonLibraryObjectChapter();
        chapter.id = this.id ?? '';
        chapter.name = this.name ?? '';
        chapter.contentType = this.contentType ?? LibraryObjectContentType.EMBEDDED;
        if (this.contentUrl) {
            chapter.contentUrl = this.contentUrl;
        }
        return chapter;
    }
}
export default LibraryObjectChapter;
